import React, { useEffect, useState } from "react";
import Request from './tool';

const Clause = (props) => {

    document.documentElement.addEventListener('touchstart', function (event) {
        if (event.touches.length > 1) {
            event.preventDefault()
        }
    }, {
        passive: false
    })
    const [service, setService] = useState()

    useEffect(() => {
        const { match: { params: { type } } } = props;
        init_data(type)
    }, [props]);

    const init_data = (type) => {
        Request({
            url: "content/getDocument",
            method: "GET",
            data: {
                type: type
            },
        }).then((res) => {
            setService(res.content)
            document.title = res.title;
        });
    }

    return (
        <div dangerouslySetInnerHTML={{ __html: service }} style={{ padding: 20 }}></div>
    );
};

export default Clause;
