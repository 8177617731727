import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { Switch } from 'react-router-dom/cjs/react-router-dom';
import './App.less';
import Clause from './clause';
import Download from './download';
import Register from './register';

export default class App extends Component {
  render() {
    return <Switch>
      <Route path='/download/app' exact component={Download} />
      <Route path='/clause/:type' exact component={Clause} />
      <Route path='/register' exact component={Register} />
    </Switch>
  }
}