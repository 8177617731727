import { Button, Input, message, Typography } from "antd";
import qs from 'query-string';
import React, { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const { Text } = Typography

const Register = (props) => {

    const [width] = useState(window.innerWidth);
    const [height] = useState(window.innerHeight);
    const [phone, setPhone] = useState('')
    const { search } = useLocation();
    const navigator = () => {
        window.location.href = 'https://api.user.sdqc.club/#/download/app'
        //window.location.href = 'http://192.168.3.205:3001/#/download/app'
    }

    useEffect(() => {
        document.title = "水到渠成送水到家";
    }, []);

    const register = () => {
        Request({
            url: "member/webRegister",
            method: "POST",
            data: {
                mobile: phone,
                token: qs.parse(search).token
            }
        }).then((res) => {
            if (res.member_id) {
                message.success('领取名额成功')
                navigator()
            } else {
                message.error(res.msg)
            }
        });
    };

    return (
        <Fragment>
            <div style={{ height: height, width: width, backgroundColor: '#fff', position: 'absolute', backgroundImage: 'url(image/register.png)', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div style={{ height: height / 100 * 33, width: '92%', left: '4%', backgroundImage: 'url(image/register_input.png)', backgroundSize: 'cover', backgroundPosition: 'center', display: 'flex', justifyContent: 'center', borderRadius: 15, marginTop: height / 100 * 63, position: 'absolute' }}>
                    <Input onChange={(e) => {
                        setPhone(e.target.value)
                    }} placeholder="请输入你的手机号" style={{ width: height / 100 * 40, height: height / 100 * 5.5, fontSize: 16, fontWeight: 600, borderColor: '#6E7EFF', borderRadius: 5, position: 'absolute', bottom: height / 100 * 23 }} />
                    <Button style={{ width: height / 100 * 40, height: height / 100 * 5.5, fontSize: 20, fontWeight: 700, background: 'linear-gradient(to bottom, #38C2FB 0%, #6387FE 100%)', borderRadius: height / 100 * 2.75, position: 'absolute', bottom: height / 100 * 12 }} onClick={() => {
                        //校验表单数据
                        if (phone.length !== 11) {
                            message.error('请输入正确的手机号')
                            return
                        }
                        register()
                    }}>
                        <Text style={{ color: '#fff' }}>领取名额并注册</Text>
                    </Button>
                    <Button onClick={() => navigator()} style={{ width: height / 100 * 40, height: height / 100 * 5.5, fontSize: 20, fontWeight: 700, borderColor: '#6982FF', borderRadius: height / 100 * 2.75, position: 'absolute', bottom: height / 100 * 5 }}>
                        <Text style={{ color: '#6982FF' }}>立即下载APP</Text>
                    </Button>
                </div>
            </div>
        </Fragment>
    );
};

export default Register;
