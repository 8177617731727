/* eslint-disable no-native-reassign */
import { message } from 'antd';
import axios from 'axios';

export default Request = (option) => {
  return new Promise((resolve, reject) => {
    axios({
      url: "https://api.user.sdqc.club/v1.0.1/api/" + option.url,
      //url: "http://192.168.3.205:8080/api/" + option.url,
      method: option.method,
      params: option.data || null,
      timeout: 10000,
      withCredentials: true,
    }).then((response) => {
      let data = response.data;
      if (data.code === 200) {
        resolve(data.result);
      } else {
        message.error(data.msg)
        reject(data.result);
      }
    });
  });
};